import React from "react";
import { BrowserRouter as Router, Link, Route, Switch } from "react-router-dom";
import "./App.css";
import { AudienceFeedback } from "./lib/AudienceFeedback";
import { Consumer } from "./lib/Consumer";
import { Producer } from "./lib/Producer";

export default function App() {
  return (
    <Router>
      <div>
        <nav>
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/consume">Consume</Link>
            </li>
            <li>
              <Link to="/produce">Produce</Link>
            </li>
            <li>
              <Link to="/feedback">Feedback</Link>
            </li>
          </ul>
        </nav>

        <Switch>
          <Route path="/consume">
            <Consumer
              eventId="123"
              token="eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjoiYXVkaWVuY2UiLCJldmVudElkIjoiMTIzIiwidXNlcklkIjoidWxhaTJmIiwibWF4RmVlZGJhY2tDb25uZWN0aW9ucyI6NSwiaWF0IjoxNTk1MzQ0NzU0fQ.FPLdN3n8CqFuRGD-619-wYDbc0e09J6qyeJxeJKk3oI"
            />
          </Route>
          <Route path="/produce">
            <Producer
              eventId="123"
              token="eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjoicHJlc2VudGVyIiwiZXZlbnRJZCI6IjEyMyIsInVzZXJJZCI6IjhqOGtsIiwiaWF0IjoxNTk1MzQ0NzA2fQ.dHLF9oRP91KbA99G50lhK9j3Zz3-orltnPeatE21bhU"
            />
          </Route>
          <Route path="/feedback">
            <AudienceFeedback
              eventId="123"
              token="eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjoiYXVkaWVuY2VGZWVkYmFjayIsImV2ZW50SWQiOiIxMjMiLCJ1c2VySWQiOiJ1bGFpMmYiLCJtYXhGZWVkYmFja0Nvbm5lY3Rpb25zIjo1LCJpYXQiOjE1OTUzNDQ3NTR9.BnJmrbVM4azYOoL8iQCjjVMHITfM_r6Mw6H8pJa7Bok"
            />
          </Route>
          <Route path="/">
            <div>Go to /produce or /consumer</div>
          </Route>
        </Switch>
      </div>
    </Router>
  );
}
