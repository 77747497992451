export interface AsyncRequestSocket extends SocketIOClient.Socket {
  request: (type: string, data?: {}) => Promise<any>;
}

function socketPromise(socket: SocketIOClient.Socket) {
  return function request(type: string, data = {}) {
    return new Promise((resolve) => {
      socket.emit(type, data, resolve);
    });
  };
}

export const asAsyncSocket = (
  socket: SocketIOClient.Socket
): AsyncRequestSocket => {
  const s = socket as AsyncRequestSocket;
  s.request = socketPromise(s);
  return s;
};
