import mediasoup, { Device } from "mediasoup-client";
import React, { useEffect, useState } from "react";
import socketClient from "socket.io-client";
import { config } from "../config";
import { asAsyncSocket, AsyncRequestSocket } from "./asyncSocket";

interface TransportParams {
  id: string;
  iceParameters: mediasoup.types.IceParameters;
  iceCandidates: mediasoup.types.IceCandidate[];
  dtlsParameters: mediasoup.types.DtlsParameters;
}

interface AnywhereInitialDataResponse {
  routerRtpCapabilities: mediasoup.types.RtpCapabilities;
  transportParams: TransportParams;
}

async function establishProducerStream(
  eventId: string,
  token: string,
  setReactions: Function
) {
  const serverUrl = `${config.rtcSignallingServer.hostname}/${eventId}?token=${token}`;
  const socket = asAsyncSocket(
    socketClient(serverUrl, config.rtcSignallingServer.socketConnectOptions)
  );

  const device = new Device();
  let transport: mediasoup.types.Transport;
  let videoProducer: mediasoup.types.Producer;
  let audioProducer: mediasoup.types.Producer;

  socket.on("connect", () => {
    console.log("Producer connected (or reconnected)");
  });

  socket.on("anywhereFeedback:aggregated", (data: any) => {
    console.log(data);
    setReactions(data);
  });

  socket.on(
    "anywhereSignal:initialProducerData",
    async ({
      routerRtpCapabilities,
      transportParams,
    }: AnywhereInitialDataResponse) => {
      console.log("anywhereSignal:initialProducerData");
      await device.load({ routerRtpCapabilities });
      transport = device.createSendTransport(transportParams);

      transport.on("connect", async ({ dtlsParameters }, callback, errback) => {
        // Emitted when the transport is about to establish the ICE+DTLS connection and needs to exchange information with the associated server side transport.
        //
        // dtlsParameters : Local DTLS parameters.
        // callback : A function that must be called by the application once the parameters have been transmitted to the associated server side transport.
        // errback : A function that must be called by the application (with the corresponding error) if the tranmission of parameters to the associated server side transport failed for any reason.
        try {
          await socket.request("anywhereSignal:connectProducerTransport", {
            transportId: transport.id,
            dtlsParameters,
          });
          callback();
        } catch (error) {
          errback(error);
        }
      });

      transport.on(
        "produce",
        async (
          {
            kind,
            rtpParameters,
          }: {
            kind: mediasoup.types.MediaKind;
            rtpParameters: mediasoup.types.RtpParameters;
          },
          callback,
          errback
        ) => {
          try {
            const { id } = await socket.request("anywhereSignal:produce", {
              transportId: transport.id,
              kind,
              rtpParameters,
            });
            callback({ id });
          } catch (err) {
            errback(err);
          }
        }
      );

      transport.on("connectionstatechange", async (state) => {
        switch (state) {
          case "connecting":
            console.log("transport connecting...");
            break;
          case "connected":
            console.log("transport connected... setting up stream");
            break;
          case "failed":
            console.log("failed :(");
            transport.close();
            break;
          default:
            break;
        }
      });

      let stream: MediaStream;

      try {
        if (!device.canProduce("video") || !device.canProduce("audio")) {
          throw Error("cannot produce both video and audio");
        }
        stream = await navigator.mediaDevices.getUserMedia({
          video: true,
          audio: true,
        });

        const videoTrack = stream.getVideoTracks()[0];
        const audioTrack = stream.getAudioTracks()[0];

        // very quick and dirty way to give the user feedback (only video)
        // as having the audio as well would create feedback
        let ownStream = new MediaStream();
        ownStream.addTrack(videoTrack);
        const v = document.querySelector("#producerVideo") as HTMLMediaElement;
        v.srcObject = ownStream;

        videoProducer = await transport.produce({ track: videoTrack });
        audioProducer = await transport.produce({ track: audioTrack });
      } catch (err) {
        throw err;
      }

      videoProducer.on("transportclose", () => {
        console.log("video transport closed so producer closed");
      });

      videoProducer.on("trackended", () => {
        console.log("video track ended");
      });

      audioProducer.on("transportclose", () => {
        console.log("audio transport closed so producer closed");
      });

      audioProducer.on("trackended", () => {
        console.log("audio track ended");
      });
    }
  );

  socket.on("liveStats", (data: any) => {
    console.log(data);
  });

  socket.on("disconnect", () => {
    console.log("socket : disconnect");
  });

  socket.on("connect_error", (error: any) => {
    console.error(
      "socket: could not connect to %s%s (%s)",
      serverUrl,
      config.rtcSignallingServer.socketConnectOptions.path,
      error.message
    );
  });

  return socket;
}

export const Producer: React.FC<{ eventId: string; token: string }> = ({
  eventId,
  token,
}) => {
  const [socket, setSocket] = useState<AsyncRequestSocket | undefined>(
    undefined
  );
  const [reactions, setReactions] = useState<any>({
    reactions: {},
    timestamp: 0,
  });
  useEffect(() => {
    const f = async (eventId: string, validationToken: string) => {
      const sock = await establishProducerStream(
        eventId,
        validationToken,
        setReactions
      );
      setSocket(sock);
    };

    const validationToken = prompt(
      "Please enter the 'presenter' token from '/createPresenter/123'",
      token
    );
    console.log("connecting to event");
    if (validationToken) {
      f(eventId, validationToken);
    }
  }, []);

  if (socket) {
    return (
      <div>
        <div>PRODUCER SOCKET CONNECTED</div>
        <video id="producerVideo" autoPlay playsInline />
        <div>
          <div>REACTIONS:</div>
          {Object.keys(reactions.reactions).map((k) => {
            return (
              <div key={k}>
                {k} : {reactions.reactions[k]}
              </div>
            );
          })}
        </div>
      </div>
    );
  }
  return <div>NO SOCKET</div>;
};
