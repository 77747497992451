import mediasoup, { Device } from "mediasoup-client";
import React, { useEffect } from "react";
import socketClient from "socket.io-client";
import { config } from "../config";
import { asAsyncSocket, AsyncRequestSocket } from "./asyncSocket";

interface TransportParams {
  id: string;
  iceParameters: mediasoup.types.IceParameters;
  iceCandidates: mediasoup.types.IceCandidate[];
  dtlsParameters: mediasoup.types.DtlsParameters;
}

interface AnywhereInitialDataResponse {
  routerRtpCapabilities: mediasoup.types.RtpCapabilities;
  transportParams: TransportParams;
  producerInfo: { producerId: string; kind: string }[];
}

const device = new Device();
let stream = new MediaStream();
let transport: mediasoup.types.Transport;
let consumers: mediasoup.types.Consumer[] = [];

async function establishConsumerStream(
  eventId: string,
  token: string,
  videoElement: HTMLMediaElement
) {
  videoElement.srcObject = stream;

  const serverUrl = `${config.rtcSignallingServer.hostname}/${eventId}?token=${token}`;
  const socket = asAsyncSocket(
    socketClient(serverUrl, config.rtcSignallingServer.socketConnectOptions)
  );

  socket.on("connect", () => {
    console.log("Consumer connected (or reconnected)");
  });

  socket.on("anywhereSignal:producerDisconnected", () => {
    console.log("producer disconnected");
    console.log("removing streams from track");
    stream.getVideoTracks().forEach((t) => stream.removeTrack(t));
    stream.getAudioTracks().forEach((t) => stream.removeTrack(t));
    consumers.forEach((consumer) => {
      console.log("closing consumer", consumer);
      consumer.close();
    });
    consumers = [];
    window.alert(
      "producer disconnected... you will be automatically reconnected if they come back"
    );
  });

  socket.on(
    "anywhereSignal:initialConsumerData",
    async ({
      routerRtpCapabilities,
      transportParams,
      producerInfo,
    }: AnywhereInitialDataResponse) => {
      console.log("anywhereSignal:initialConsumerData");
      await device.load({ routerRtpCapabilities });
      transport = device.createRecvTransport(transportParams);

      transport.on("connect", async ({ dtlsParameters }, callback, errback) => {
        // Emitted when the transport is about to establish the ICE+DTLS connection and needs to exchange information with the associated server side transport.
        //
        // dtlsParameters : Local DTLS parameters.
        // callback : A function that must be called by the application once the parameters have been transmitted to the associated server side transport.
        // errback : A function that must be called by the application (with the corresponding error) if the tranmission of parameters to the associated server side transport failed for any reason.
        try {
          await socket.request("anywhereSignal:connectConsumerTransport", {
            transportId: transport.id,
            dtlsParameters,
          });
          callback();
        } catch (error) {
          errback(error);
        }
      });

      transport.on("connectionstatechange", async (state) => {
        switch (state) {
          case "connecting":
            console.log("transport connecting...");
            break;
          case "connected":
            console.log("transport connected... setting up stream");
            // videoElement.srcObject = stream;
            await socket.request("anywhereSignal:resume");
            break;
          case "failed":
            console.log("failed :(");
            transport.close();
            break;
          default:
            break;
        }
      });

      if (producerInfo.length > 0) {
        console.log(
          "calling 'anywhereSignal:consume' as there is at least one track:",
          producerInfo.length
        );
        const asyncRes = await Promise.all(
          producerInfo.map(async (i) => {
            const res = await consume(socket, i);
            return res;
          })
        );
        console.log(asyncRes);
      }
    }
  );

  socket.on("disconnect", () => {
    console.log("socket : disconnect");
  });

  socket.on("connect_error", (error: any) => {
    console.error(
      "socket: could not connect to %s%s (%s)",
      serverUrl,
      config.rtcSignallingServer.socketConnectOptions.path,
      error.message
    );
  });

  socket.on(
    "anywhereSignal:producerNowLive",
    async (producerInfo: { producerId: string; kind: string }) => {
      console.log("socket: producer now live");
      if (consumers.find((c) => c.producerId === producerInfo.producerId)) {
        console.log("already subscribed to producer");
      } else {
        await consume(socket, producerInfo);
      }
    }
  );

  return socket;
}

async function consume(
  socket: AsyncRequestSocket,
  producerInfo: { producerId: string; kind: string }
) {
  console.log("will try and consume", producerInfo);
  const consumerOptions: mediasoup.types.ConsumerOptions = await socket.request(
    "anywhereSignal:consume",
    {
      rtpCapabilities: device.rtpCapabilities,
      producerInfo,
    }
  );
  console.log("awaiting 'transport.consume'");
  const consumer = await transport.consume(consumerOptions);
  consumers.push(consumer);

  consumer.on("transportclose", () => {
    console.log("transport closed so consumer closed");
  });

  consumer.on("trackended", () => {
    console.log("external track has ended");
  });
  console.log("adding track to stream", consumer.track);
  stream.addTrack(consumer.track);
}

export const Consumer: React.FC<{ eventId: string; token: string }> = ({
  eventId,
  token,
}) => {
  useEffect(() => {
    const validationToken = prompt(
      "Please enter the 'audienceMember' token from the pair created by '/createAudienceMember/123'",
      token
    );
    console.log("connecting to event");
    if (validationToken) {
      establishConsumerStream(
        eventId,
        validationToken,
        document.querySelector("#consumerVideo") as HTMLMediaElement
      );
    }
  }, []);

  return (
    <div>
      <video id="consumerVideo" autoPlay playsInline />
    </div>
  );
};
