import React, { useEffect, useState } from "react";
import socketClient from "socket.io-client";
import { config } from "../config";
import { asAsyncSocket, AsyncRequestSocket } from "./asyncSocket";

interface AudienceFeedbackData {
  // should match the server type
  // might be worth introducing a shared lib for types/validation?
  timestamp: number;
  msg: string;
}

const feedback = (msg: string): AudienceFeedbackData => {
  return {
    timestamp: Date.now(),
    msg,
  };
};

const extablishAudienceFeedback = (eventId: string, token: string) => {
  const serverUrl = `${config.rtcSignallingServer.hostname}/events/${eventId}?token=${token}`;
  const socket = asAsyncSocket(
    socketClient(serverUrl, config.rtcSignallingServer.socketConnectOptions)
  );

  socket.on("connect", () => {
    console.log("Feedback connected (or reconnected)");
  });

  return socket;
};

const emotions: { [key: string]: string } = {
  laugh: "😂",
  cry: "😭",
  clap: "👏",
  shock: "😲",
  angry: "😠",
  rollingEyes: "🙄",
  vomit: "🤮",
  blush: "😳",
  grimace: "😬",
  neutral: "😐",
  skull: "💀",
};

const resolveEmotion = (emotion: string) => {
  if (emotions[emotion]) {
    return emotions[emotion];
  } else {
    return emotions["skull"];
  }
};

const EmojiButton: React.FC<{
  emotion: string;
  socket: AsyncRequestSocket;
}> = ({ emotion, socket }) => {
  const [hover, setHover] = useState(false);
  const [clicked, setClicked] = useState(false);
  const click = () => {
    setClicked(true);
    socket.emit("anywhereFeedback:feedback", {
      reaction: emotion,
      timestamp: Date.now(),
    });
    setTimeout(() => setClicked(false), 200);
  };
  return (
    <button
      onMouseEnter={() => setHover(!hover)}
      onMouseLeave={() => setHover(!hover)}
      onClick={() => click()}
      type="button"
      style={{
        fontSize: "10rem",
        background: "transparent",
        opacity: clicked ? 0 : hover ? "1" : "0.7",
        border: "none",
        cursor: "pointer",
        outline: "none",
      }}
    >
      {resolveEmotion(emotion)}
    </button>
  );
};

export const AudienceFeedback: React.FC<{ eventId: string; token: string }> = ({
  eventId,
  token,
}) => {
  const [socket, setSocket] = useState<AsyncRequestSocket | undefined>(
    undefined
  );
  const [emotionNames, setEmotions] = useState<string[]>([]);
  useEffect(() => {
    const validationToken = prompt(
      "Please enter the 'audienceFeedback' token from the pair created by '/createAudienceMember/123'",
      token
    );
    if (validationToken) {
      console.log("connecting to event");
      const sock = extablishAudienceFeedback(eventId, validationToken);
      sock.on(
        "anywhereFeedback:initialData",
        ({ emotions }: { emotions: string[] }) => {
          setEmotions(emotions);
        }
      );
      sock.on("anywhereFeedback:aggregated", (data: any) => {
        console.log(data);
      });
      setSocket(sock);
    }
  }, []);

  if (!socket) {
    return <div>Initializing socket</div>;
  }
  if (emotionNames.length === 0) {
    return <div>Waiting for server to send emotions</div>;
  } else {
    return (
      <div style={{ textAlign: "center" }}>
        {emotionNames.map((emotion, index) => (
          <EmojiButton key={index} emotion={emotion} socket={socket} />
        ))}
      </div>
    );
  }
};
