export const config = {
  rtcSignallingServer: {
    hostname:
      process.env.REACT_APP_ANYWHERE_ANNOUNCED_IP || "http://localhost:8888",
    socketConnectOptions: {
      path: "/server",
      transports: ["websocket"],
    },
  },
};
